import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'urql';
import InformationProvider from '../../context/InformationsContext';
import InputField from '../../shared/InputField';
import BrynsonSquareButton from '../../shared/BrynsonSquareButton';
import BreadCrumbs from "../../shared/BreadCrumbs";
import { useParams } from 'react-router-dom';
import DesktopTitle1 from "../../shared/DesktopH5"

const TRACKINGS_QUERY = `
  query Trackings($id: ID!) {
    trackings(id: $id) {
      id
      name
      propertyWebsiteUrl
      leasingApplicationOrPortal
      residentPortalUrl
      chatUrl
      tourUrl
      schedulingUrl
      zillowEmail
      zillowPhone
    }
  }
`;

const UPDATE_TRACKINGS_MUTATION = `
  mutation UpdateTrackingsMutation($id: ID!, $propertyWebsiteUrl: String, $leasingApplicationOrPortal: String, $residentPortalUrl: String, $chatUrl: String, $tourUrl: String, $schedulingUrl: String, $zillowEmail: String, $zillowPhone: String) {
    updateTrackingsMutation(id: $id, propertyWebsiteUrl: $propertyWebsiteUrl, leasingApplicationOrPortal: $leasingApplicationOrPortal, residentPortalUrl: $residentPortalUrl, chatUrl: $chatUrl, tourUrl: $tourUrl, schedulingUrl: $schedulingUrl, zillowEmail: $zillowEmail, zillowPhone: $zillowPhone) {
      result
      errors
    }
  }
`;

export default function Tracking({ params }) {
  const page = "Tracking"
  const [{ data: trackingData, fetching }] = useQuery({
    query: TRACKINGS_QUERY,
    variables: { id: params.id },
  });

  const [tracking, setTracking] = useState({
    propertyWebsiteUrl: '',
    leasingApplicationOrPortal: '',
    residentPortalUrl: '',
    chatUrl: '',
    tourUrl: '',
    schedulingUrl: '',
    zillowEmail: '',
    zillowPhone: '',
  });

  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (trackingData && trackingData.trackings) {
      setTracking({
        propertyWebsiteUrl: trackingData.trackings.propertyWebsiteUrl,
        leasingApplicationOrPortal: trackingData.trackings.leasingApplicationOrPortal,
        residentPortalUrl: trackingData.trackings.residentPortalUrl,
        chatUrl: trackingData.trackings.chatUrl,
        tourUrl: trackingData.trackings.tourUrl,
        schedulingUrl: trackingData.trackings.schedulingUrl,
        zillowEmail: trackingData.trackings.zillowEmail,
        zillowPhone: trackingData.trackings.zillowPhone,
      });
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "Dashboard" },
        { link: "/portal/listings", title: `Property ${trackingData.trackings.name}` },
        { link: null, title: "Tracking" }
      ];
      setBreadCrumbs(breadCrumbs);
    }
  }, [trackingData]);

  const [, updateTrackings] = useMutation(UPDATE_TRACKINGS_MUTATION);

  const handleChange = (field) => (e) => {
    setTracking({ ...tracking, [field]: e.target.value });
  };

  const handleUpdate = () => {
    const { propertyWebsiteUrl, leasingApplicationOrPortal, residentPortalUrl, chatUrl, tourUrl, schedulingUrl, zillowEmail, zillowPhone } = tracking;
    updateTrackings({
      id: params.id,
      propertyWebsiteUrl,
      leasingApplicationOrPortal,
      residentPortalUrl,
      chatUrl,
      tourUrl,
      schedulingUrl,
      zillowEmail,
      zillowPhone,
    });
  };

  return (
    <div className="p-8">
      {breadCrumbs && <BreadCrumbs items={breadCrumbs} />}
      <DesktopTitle1 content={`Edit ${page}`} />
      <div className="flex">
        <div className="w-1/3 sm:w-full">
          {Object.keys(tracking).map((key) => (
            <div className="mb-2 w-full" key={key}>
              <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</label>
              <InputField
                id={key}
                placeholder={key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                value={tracking[key] || ''}
                type="text"
                onChange={handleChange(key)}
              />
            </div>
          ))}
          <BrynsonSquareButton
            type="primary"
            testId="update-tracking-button"
            className="mt-4"
            onClick={handleUpdate}
            label="Update Tracking"
          />
        </div>
      </div>
    </div>
  );
}