import React, { useContext, useState, useEffect } from "react"
import { useQuery } from "urql"
import { GlobalContext } from "../../context/GlobalContext"
import Selectbox from "../../shared/Selectbox"
import AGGridTable from '../../shared/AGGridTable'
import BrynsonTabs from "../../shared/BrynsonTabs"
import Loading from "../../shared/Loading"
import { ResponsivePie } from '@nivo/pie'


const ILS_TYPES = ['apartments_com', 'rent_com', 'zillow', 'apartment_list']
const ILS_LABELS = {
  'apartments_com': 'CoStar',
  'rent_com': 'Rent.com',
  'zillow': 'Zillow',
  'apartment_list': 'Apartment List'
}
const tabs = ILS_TYPES.map(x => ({label: ILS_LABELS[x], icon: null}))

const EXTRACTED_LISTINGS_QUERY = `
  query ExtractedListings($propertyId: ID!) {
    extractedListings(propertyId: $propertyId) {
      id
      title
      listingLink
      address
      price
      beds
      baths
      featureInfo
      amenities
      virtualTour
      package
      ilsSource
      specials
      source
      sponsored
      contract
    }
  }
`;

export default function ILSIntelligence() {
  const [selectedProperty, setSelectedProperty] = useState(null)
  const { propertiesData } = useContext(GlobalContext)

  const propertiesOptions = propertiesData?.properties?.map(x => ({ value: x.id, label: x.name })) || []

  useEffect(() => {
    if (propertiesOptions.length > 0 && !selectedProperty) {
      setSelectedProperty(propertiesOptions[0].value)
    }
  }, [propertiesOptions, selectedProperty])

  const [result] = useQuery({
    query: EXTRACTED_LISTINGS_QUERY,
    variables: { propertyId: selectedProperty },
    pause: !selectedProperty
  })

  const { data, fetching, error } = result

  const filteredTabs = tabs.filter(tab =>
    data?.extractedListings?.some(listing => ILS_LABELS[listing.source] === tab.label)
  )

  const columnDefs = [
    { title: "Title", accessor: "title", type: "string" },
    { title: "Package", accessor: "package", type: "string" },
    { title: "Address", accessor: "address", type: "string" },
    { title: "Price", accessor: "price", type: "string" },
    { title: "Beds", accessor: "beds", type: "string" },
    { title: "Baths", accessor: "baths", type: "string" },
    { title: "Listing Link", accessor: "listingLink", type: "string" },
    { title: "Specials", accessor: "specials", type: "string" },
    { title: "Source", accessor: "ils_source", type: "string" },
    { title: "Virtual Tour", accessor: "virtualTour", type: "boolean" },
    { title: "Contract", accessor: "contract", type: "string" },
  ]

  const prepareChartData = (listings) => {
    const packageCounts = listings.reduce((acc, listing) => {
      acc[listing.package] = (acc[listing.package] || 0) + 1
      return acc
    }, {})

    return Object.entries(packageCounts).map(([key, value]) => ({
      id: key,
      value: value,
    }))
  }

  const renderTabContent = (ilsType) => {
    const filteredData = data?.extractedListings.filter(
      listing => ILS_LABELS[listing.source] === ilsType
    ) || []

    const chartData = prepareChartData(filteredData)

    return (
      <div>
        <div style={{ height: '400px' }}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
          />
        </div>
        <AGGridTable
          data={filteredData}
          headerColumns={columnDefs}
          customCellRenderers={{}}
          customHeaderRenderers={{}}
        />
      </div>
    )
  }

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl mb-6">ILS Intelligence</h1>
      <Selectbox
        options={propertiesOptions}
        id="brynson-property"
        value={selectedProperty}
        onChange={(value) => {
          setSelectedProperty(value)
        }}
      />
      {fetching && <Loading />}
      {error && <p className="text-red-500">Error: {error.message}</p>}
      {data && filteredTabs.length > 0 ? (
        <div className="mt-4">
          <BrynsonTabs tabs={filteredTabs} className="">
            {filteredTabs.map((tab, index) => (
              <div key={index}>
                {renderTabContent(tab.label)}
              </div>
            ))}
          </BrynsonTabs>
        </div>
      ) : (
        !fetching && <p className="mt-4">No data available for the selected property.</p>
      )}
    </div>
  )
}