import clsx from "clsx"
import { Table, Pagination } from "flowbite-react" // Import Pagination
import { Line } from "peity-react"
import React, { useState, useEffect } from "react"

const UnitsTable = ({ properties = [] }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  useEffect(() => {
    setCurrentPage(1)
  }, [properties])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const getPrice = (history) => {
    if (history.length > 0) {
      return history[history.length - 1].price
    }
    return 0
  }

  const getAskPsf = (history, sqft) => {
    if (history.length > 0 && sqft > 0) {
      return Math.round(history[history.length - 1].price / sqft)
    }
    return 0
  }

  const getEffPrice = (history) => {
    if (history.length > 0) {
      return history[history.length - 1].effective_price
    }
    return 0
  }

  const getEffPsf = (history, sqft) => {
    if (history.length > 0 && sqft > 0) {
      return Math.round(history[history.length - 1].effective_price / sqft)
    }
    return 0
  }

  const formatUnits = (properties) => {
    const units = []
    properties.forEach((property) => {
      property.building_availability.forEach((unit) => {
        const unitData = {
          property: property.building_name,
          askPsf: getAskPsf(unit.history, unit.sqft),
          effPsf: getEffPsf(unit.history, unit.sqft),
          askRent: getPrice(unit.history),
          effRent: getEffPrice(unit.history),
          ...unit
        }
        units.push(unitData)
      })
    })
    return units
  }

  const units = formatUnits(properties)
  const totalPages = Math.ceil(units.length / itemsPerPage)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentUnits = units.slice(indexOfFirstItem, indexOfLastItem)

  const LineChart = ({ history }) => {
    const data = history.map((item) => item.price) || [0, 0, 0, 0, 0]
    return <Line values={data} height="16" min="0" max="100" />
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Building Name</Table.HeadCell>
          <Table.HeadCell>Floorplan</Table.HeadCell>
          <Table.HeadCell>Unit</Table.HeadCell>
          <Table.HeadCell>Floor</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Sf</Table.HeadCell>
          <Table.HeadCell>Available On</Table.HeadCell>
          <Table.HeadCell>Last Ask Rent</Table.HeadCell>
          <Table.HeadCell>Ask Psf</Table.HeadCell>
          <Table.HeadCell>Last Eff Rent</Table.HeadCell>
          <Table.HeadCell>Eff Psf</Table.HeadCell>
          <Table.HeadCell>History</Table.HeadCell>
          <Table.HeadCell>On Mkt Date</Table.HeadCell>
          <Table.HeadCell>Leased Date</Table.HeadCell>
          <Table.HeadCell>Days On Mkt</Table.HeadCell>
        </Table.Head>
        <Table.Body className="border-t text-sm">
          {currentUnits.map((item, index) => (
            <Table.Row key={index} className={clsx('border-b font-medium text-gray-800 hover:bg-blue-100')}>
              <Table.Cell className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
                {item.property}
              </Table.Cell>
              <Table.Cell>{item.floorplan_name}</Table.Cell>
              <Table.Cell>{item.unit_name}</Table.Cell>
              <Table.Cell>{item.floor}</Table.Cell>
              <Table.Cell>
                <div
                  style={{
                    backgroundColor: item.bed === 3
                      ? 'rgb(255, 87, 34)'
                      : item.bed === 2
                        ? 'rgb(76, 175, 80)'
                        : item.bed === 1
                          ? '#6365f1'
                          : item.bed === 0
                            ? 'rgb(255, 193, 7)'
                            : 'transparent',
                    display: 'inline-block',
                    borderRadius: '10px',
                    width: '36px',
                    height: '18px',
                    lineHeight: '12px',
                    paddingTop: '2px',
                    color: 'white',
                    textAlign: 'center',
                  }}>
                  {item.bed}/{item.bath}
                </div>
              </Table.Cell>
              <Table.Cell>{item.sqft}</Table.Cell>
              <Table.Cell>{item.availability}</Table.Cell>
              <Table.Cell>{item.askRent}</Table.Cell>
              <Table.Cell>{item.askPsf}</Table.Cell>
              <Table.Cell>{item.effRent}</Table.Cell>
              <Table.Cell>{item.effPsf}</Table.Cell>
              <Table.Cell>
                <LineChart history={item.history} />
              </Table.Cell>
              <Table.Cell>{item.enter_market}</Table.Cell>
              <Table.Cell>{item.exit_market}</Table.Cell>
              <Table.Cell>{item.days_on_market}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <div className="my-4 flex justify-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          showIcons={true}
          previousLabel="Previous"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default UnitsTable;
