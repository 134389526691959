import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

const activitylog = require("../../../../../public/samples/activitylog");

export default function ActivityLog() {
  const { currentUserData } = useContext(GlobalContext);

  const about = currentUserData?.currentUser?.currentCompany?.about;

  const activityLogUrls = {
    'Olympus Property': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQm-9JzaIwb23DGjTllRJ6O64GLokdPtH4_V5NZdyGIlhMcEzYzcR4EUVe2r44ZDQljuWO5w6YWBQSV/pubhtml?widget=true&amp;headers=false",
    'Bruns Realty Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ_3EEYrc9VlLXHvasg5W096GGnLjJe4U9PMBzXvHNHKbW-5Z-87AaSLU-LS5Bdfhji01EHUiIq69S/pubhtml?widget=true&amp;headers=false",
    'AMBO Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRgJFNSWox4ppuE6eN3bXA7HiI5yQfWTgtIaGXbNmCWVVzybDNL8sXURirHmH7TwarWEOs40VVxZSAI/pubhtml?widget=true&amp;headers=false",
    'Aberly Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSD9LvZWbi7Y0eUDZ5Llb1jCnz9RGueaWt-T2fh5U_r4XTQczZ9DgQsF7LgTpZuBuw-f7DT9WL6Lyt7/pubhtml?widget=true&amp;headers=false",
    'Bellrock Real Estate Partners LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTJ_SlT8haCcuPdG8wNGmUzYke3PUu6Q_HdM9PKiB64HKpSKufoeY-YDUEZBolAqzpEBntqL1Ub8LjZ/pubhtml?widget=true&amp;headers=false",
    'Chelsea Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQAlRQofcfrjD-XWC1zGxdsH_m3YqNjl5XDAg80rrK1B00Bb6TmIoi-dIHR3icvt2wQ_avS35-5fL8Z/pubhtml?widget=true&amp;headers=false",
    'Dawn Homes Management Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSbTVKi-xTvBMQ-TZjJ7Kp3droCRUhkhhyP7yYgteXXTc6Wzc2Ui3kWB4PXb21Bo03Bb9F3tM7KkDLi/pubhtml?widget=true&amp;headers=false",
    'Finning Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT3euZuJ8uqodSyaPKd_FqxP_rlv-twnx-F5Xbis7-EtpbhJ44G1FHzGGXEW640VbRMqTEYUZtuS5QP/pubhtml?widget=true&amp;headers=false",
    'Goldfarb Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQbQon5CKmRoUeLM_S5UNmmMrMDmJ0z1JNV75vYhNLVqDCIN9C6h076SUXFokQbD5BaHeTUG8LykfLd/pubhtml?widget=true&amp;headers=false",
    'Judge Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQpbeyo-6ZbR-lyU4TaUjw_6wURMeaFngqEt0nwMPmtOzO-UuBxbWoIo7jU0ACrhnl7ADarZeMgwWSZ/pubhtml?widget=true&amp;headers=false",
    'M3 Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ4j8FJ08UYDLlHIwcCbelf1sXTHnrc5rkSwm0UwTaPu8DOer4ZvU8dXKLh03HwmYsdw2fPMg7m1ldE/pubhtml?widget=true&amp;headers=false",
    'Olive Tree Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRSNqfUMFAxoNBpr_P0rVUDyRwy7yX8VJolMMjbvFpt2L97RcCNsllu75IjJqkjUhVnwdS5DqDGmbXl/pubhtml?widget=true&amp;headers=false",
    'Omni Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSzanL-4QfiChH2fFdcVZAA4oN7nmHXGmTz792UN4JJjDdbGE_pEU_-HpqIDcbQCOZaLqIy2paGJZdD/pubhtml?widget=true&amp;headers=false",
    'REM Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSsSx3Y2y7Xg0P2FW8a_KjKMcUrnk1E4jrsDXCVxP9799p-d2C4VDGUTL2SC1WeCclzrrntzqkPBVSm/pubhtml?widget=true&amp;headers=false",
    'Skytian Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT2F-PVM-8LWESphArgvePsa7viJ25NUoPSCMv5PvVgRZ50N7t6PRblAM0os0ZtoDxovWUWTmrgbrY1/pubhtml?widget=true&amp;headers=false",
    'Tri City Rentals': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTrn56I16057Zrj60cmKIu28jpmBQpyQLWMW5wIHVOVPZlMu5MR8s7rZHn9rm6Y-1OKFDqBVHSSc76t/pubhtml?widget=true&amp;headers=false",
    'WPHS Venture Partners': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_kQPX8n0aIBlUgNwcsmMdqZEoMPayoOaMqH8UJ8TUruEaafclBL4lQ0i9vlmQ3NqbSwrBFdg_irLu/pubhtml?widget=true&amp;headers=false",
    'Westmere Village': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSg9Y7gKz0Op2jSHd8RMrkaDM2ihnIGwYHYCyjTYVzQq6Wb_uoLZRRui5hpKFp_UHCuGCO5T8nVXvaU/pubhtml?widget=true&amp;headers=false",
    'Prime Management LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRMZRP1-Z6UuG4tqt64hc1xi1uTR-3oknS5tV3BQM7UySMT5ms3QIToHaeuuu8ha1aWs23Yfkkmb4dj/pubhtml?widget=true&amp;headers=false",
    'Velo Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRIOgsN1YLCx_RVXSWHEzSeKn7qtwP3MEADabjiPNe4EQRPI56fQpBff5CP6Cp3bRu1wSxm8F-Y_1nV/pubhtml?widget=true&amp;headers=false",
    'The Sterling Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSZ8cassVanL8QXLeCZtxXaai89hBmXw9awE6uEvdJ5cW7hon28eEW4uNr5EyhSpiVXRhrCscfyH4Nq/pubhtml?widget=true&amp;headers=false",
    'Living Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT7J3e04hkevTX7wA87LsiUYVig_ft-1ciTjhYuc-FuMf8CZqCLJEibZnMVuWivLJpjR4WIXRFVjRly/pubhtml?widget=true&amp;headers=false"
  };

  const activityLogUrl = activityLogUrls[about];

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl">Activity Log</h1>
      <div className="w-full">
        {activityLogUrl ? (
          <iframe
            src={activityLogUrl}
            frameBorder="0"
            width="100%"
            height="900"
          />
        ) : (
          <div className="flex justify-center align-center mt-8">
            <img src={activitylog} alt="Activity Log" />
          </div>
        )}
      </div>
    </div>
  );
}
